<!--  -->
<template>
  <div class=''>
    <div class="pageTop">疫情检测实时监控平台</div>
    <el-divider></el-divider>
    <el-row>
      <div style="font-size: 20px">{{txt1}}检测统计：{{txt2}}社区/村共{{num1}}栋/组，{{num2}}户，{{num3}}人；已上报：{{num4}}人，未检测：{{num5}}人，检测率：{{num6}}%，其中异常{{num7}}人</div>
    </el-row>
    <el-row style="margin-top: 20px">
    <el-switch
        v-model="value"
        active-text="核酸统计"
        inactive-text="抗原统计"
        @change="change1">
    </el-switch>
    </el-row>
    <el-table
        :data="buildData0"
        border
        style="width: 100%">
      <el-table-column          prop="buildName"          label="楼栋名">      </el-table-column>
      <el-table-column          prop="buildLiveNum"          label="人数">      </el-table-column>
      <el-table-column
          prop="buildInfo"
          label="楼栋详情">
        <template v-slot="scope1">
          <el-popover
              title="楼栋详情"
              placement="right"
              trigger="click"
              width="auto">
            <el-table
                :data="scope1.row.InBuildData"
                style="width: 100%">

              <el-table-column                prop="room"                label="房间/门牌">            </el-table-column>
              <el-table-column                prop="num"                label="人数">            </el-table-column>
              <el-table-column                prop="unusual"                label="异常">            </el-table-column>
              <el-table-column                prop="untested"                label="未测">            </el-table-column>
              <el-table-column                prop="host"                label="户主">            </el-table-column>
              <el-table-column                prop="phone"                label="电话">            </el-table-column>

            </el-table>

            <el-button slot="reference" size="mini" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

    </el-table>
<!--    <el-row style="margin-top: 20px">
    <el-button @click="dialogVisible1 = true" style="width: 100px">
      <div style="font-size: 20px">1号楼</div>
      <div style="font-size: 15px">25人</div>
    </el-button>
      <el-dialog title="1号楼详情"
                 :visible.sync="dialogVisible1"
                 width="70%">
        <template>
          <el-table
              :data='buildData1'
              style="width: 100%">
            <el-table-column                prop="room"                label="房间/门牌">            </el-table-column>
            <el-table-column                prop="num"                label="人数">            </el-table-column>
            <el-table-column                prop="unusual"                label="异常">            </el-table-column>
            <el-table-column                prop="untested"                label="未测">            </el-table-column>
            <el-table-column                prop="host"                label="户主">            </el-table-column>
            <el-table-column                prop="phone"                label="电话">            </el-table-column>
          </el-table>
        </template>
      </el-dialog>

      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible2 = true">
        <div style="font-size: 20px">2号楼</div>
        <div style="font-size: 15px">21人</div>
      </el-button>
      <el-dialog title="2号楼详情"
                 :visible.sync="dialogVisible2"
                 width="70%">
        <template>
          <el-table
              :data='buildData2'
              style="width: 100%">
            <el-table-column                prop="room"                label="房间/门牌">            </el-table-column>
            <el-table-column                prop="num"                label="人数">            </el-table-column>
            <el-table-column                prop="unusual"                label="异常">            </el-table-column>
            <el-table-column                prop="untested"                label="未测">            </el-table-column>
            <el-table-column                prop="host"                label="户主">            </el-table-column>
            <el-table-column                prop="phone"                label="电话">            </el-table-column>
          </el-table>
        </template>
      </el-dialog>

      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible3 = true">
        <div style="font-size: 20px">3号楼</div>
        <div style="font-size: 15px">29人</div>
      </el-button>
      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible4 = true">
        <div style="font-size: 20px">4号楼</div>
        <div style="font-size: 15px">27人</div>
      </el-button>
      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible5 = true">
        <div style="font-size: 20px">5号楼</div>
        <div style="font-size: 15px">25人</div>
      </el-button>
      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible6 = true">
        <div style="font-size: 20px">6号楼</div>
        <div style="font-size: 15px">26人</div>
      </el-button>
      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible7 = true">
        <div style="font-size: 20px">7号楼</div>
        <div style="font-size: 15px">38人</div>
      </el-button>
      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible8 = true">
        <div style="font-size: 20px">8号楼</div>
        <div style="font-size: 15px">31人</div>
      </el-button>
      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible9 = true">
        <div style="font-size: 20px">9号楼</div>
        <div style="font-size: 15px">29人</div>
      </el-button>
      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible10 = true">
        <div style="font-size: 20px">10号楼</div>
        <div style="font-size: 15px">30人</div>
      </el-button>
      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible11 = true">
        <div style="font-size: 20px">物业</div>
        <div style="font-size: 15px">0人</div>
      </el-button>
      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible12 = true">
        <div style="font-size: 20px">12号楼</div>
        <div style="font-size: 15px">24人</div>
      </el-button>
    </el-row>

    <el-row style="margin-top: 10px">
      <el-button  @click="dialogVisible13 = true" style="width: 100px">
        <div style="font-size: 20px">13号楼</div>
        <div style="font-size: 15px">34人</div>
      </el-button>
      <el-button style="margin-left: 10px;width: 100px" @click="dialogVisible14 = true">
        <div style="font-size: 20px">14号楼</div>
        <div style="font-size: 15px">25人</div>
      </el-button>
    </el-row>-->

    <el-divider></el-divider>
    <el-row>
      <el-button type="primary" @click="dialogVisibleOne = true" style="width: 150px;margin-left: 50px">
        <div style="font-size: 15px">查看未上报楼栋</div>
      </el-button>
      <el-dialog title="未完成上报的楼栋"
                 :visible.sync="dialogVisibleOne"
                 width="70%">
        <template>
          <el-table
              :data='otherData1'
              style="width: 100%">
            <el-table-column                prop="buildName"                label="楼栋名">            </el-table-column>
            <el-table-column                prop="manager"                label="楼长">            </el-table-column>
            <el-table-column                prop="managerPhone"                label="楼长电话">            </el-table-column>
          </el-table>
        </template>
      </el-dialog>

      <el-button type="primary" @click="dialogVisibleTwo = true" style="width: 150px;margin-left: 200px">
        <div style="font-size: 15px">查看居民求助</div>
      </el-button>
      <el-dialog title="查看居民求助"
                 :visible.sync="dialogVisibleTwo"
                 width="70%">
        <template>
          <el-table
              :data='otherData2'
              style="width: 100%">
            <el-table-column                prop="buildName"                label="楼栋名">            </el-table-column>
            <el-table-column                prop="roomNum"                label="房间号">            </el-table-column>
            <el-table-column                prop="remark"                label="备注">            </el-table-column>
            <el-table-column                prop="residentPhone"                label="联系电话">            </el-table-column>
            <el-table-column                prop="helpDate"                label="提交日期">            </el-table-column>
          </el-table>
        </template>
      </el-dialog>

      <el-button type="primary"  style="width: 150px;margin-left: 200px">
        <div style="font-size: 15px">开启下轮检测</div>
      </el-button>

      <el-button type="primary" @click="dialogVisibleFour = true" style="width: 150px;margin-left: 200px">
        <div style="font-size: 15px">查看历史</div>
      </el-button>
      <el-dialog title="查看社区历史检测结果"
                 :visible.sync="dialogVisibleFour"
                 width="70%">
        <template>
          <el-table
              :data='otherData4'
              style="width: 100%">
            <el-table-column                prop="historyDate"                label="日期">            </el-table-column>
            <el-table-column                prop="testTimes"                label="次数">            </el-table-column>
            <el-table-column                prop="details"                label="查看详情">


              <template v-slot="scope">
                <el-popover trigger="hover" placement="top">
                  <div>本社区的{{ scope.row.historyDate }}日{{scope.row.testTimes }}{{kind}}检测结果统计：</div>
                  <div>共{{ scope.row.otherNum1 }}人需上报；已上报：{{scope.row.otherNum2}}人，未上报{{scope.row.otherNum3}}人，检测率：{{scope.row.otherNum4}}，其中异常{{scope.row.otherNum5}}人</div>
                  <div slot="reference" class="name-wrapper">
                    <el-tag size="medium">查看详情</el-tag>
                  </div>
                </el-popover>
              </template>
            </el-table-column>

          </el-table>
        </template>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    return {
      value:false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisibleOne: false,
      dialogVisibleTwo: false,

      dialogVisibleFour:false,
      innerVisibleOne: false,

      kind:'',
      kind1:'抗原',
      kind2:'核酸',

      txt1:'',      txt1_1:'抗原',      txt1_2:'核酸',
      txt2:'好第坊',
      num1:'79',
      num2:'1002',
      num3:'2539',
      num4:'',      num4_1:'4',      num4_2:'0',
      num5:'',      num5_1:'2535',      num5_2:'2539',
      num6:'',      num6_1:'0.2',      num6_2:'0.0',
      num7:'0',

      buildData0:[{}],
      buildData0_1:[{
        buildName:'1号楼',
        buildLiveNum:'25',
        InBuildData:[{
          room:'101',        num:'4',        unusual:'0',
          untested:'1',        host:'肯德基',        phone:'4008823823',
        },{
          room:'102',          num:'5',          unusual:'0',
          untested:'5',          host:'麦当劳',          phone:'4008517517',
        },{
          room:'103',        num:'3',        unusual:'0',
          untested:'3',        host:'',        phone:'',
        }],
      },{
        buildName:'2号楼',
        buildLiveNum:'21',
        InBuildData:[{
          room:'101',        num:'0',        unusual:'0',
          untested:'0',        host:'',        phone:'',
        },{
          room:'102',          num:'5',          unusual:'0',
          untested:'4',          host:'汉堡王',          phone:'',
        },{
          room:'201',        num:'4',        unusual:'0',
          untested:'4',        host:'',        phone:'',
        }],
      }],
      buildData0_2:[{
        buildName:'1号楼',
        buildLiveNum:'25',
        InBuildData:[{
          room:'101',        num:'4',        unusual:'0',
          untested:'4',        host:'肯德基',        phone:'4008823823',
        },{
          room:'102',        num:'5',        unusual:'0',
          untested:'5',        host:'麦当劳',        phone:'4008517517',
        },{
          room:'103',        num:'3',        unusual:'0',
          untested:'3',        host:'',        phone:'',
        }],
      },{
        buildName:'2号楼',
        buildLiveNum:'21',
        InBuildData:[{
          room:'101',        num:'0',        unusual:'0',
          untested:'0',        host:'',        phone:'',
        },{
          room:'102',        num:'5',        unusual:'0',
          untested:'5',        host:'汉堡王',        phone:'',
        },{
          room:'201',        num:'4',        unusual:'0',
          untested:'4',        host:'',        phone:'',
        }],
      }],


      otherData1:[{}],
      otherData1_1:[{
        buildName:'1号楼',        manager:'103',        managerPhone:'',
      },{
        buildName:'2号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'3号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'4号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'5号楼',        manager:'502',        managerPhone:'',
      },{
        buildName:'6号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'7号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'8号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'9号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'10号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'物业',        manager:'',        managerPhone:'114514',
      },{
        buildName:'12号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'13号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'14号楼',        manager:'',        managerPhone:'',
      },],
      otherData1_2:[{
        buildName:'1号楼',        manager:'103',        managerPhone:'',
      },{
        buildName:'2号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'3号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'4号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'5号楼',        manager:'502',        managerPhone:'',
      },{
        buildName:'6号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'7号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'8号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'9号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'10号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'物业',        manager:'',        managerPhone:'114514',
      },{
        buildName:'12号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'13号楼',        manager:'',        managerPhone:'',
      },{
        buildName:'14号楼',        manager:'',        managerPhone:'',
      },],

      otherData4:[{}],
      otherData4_1:[{
        historyDate:'2022-06-07',
        testTimes:'第3次',
        otherNum1:'2539',
        otherNum2:'4',
        otherNum3:'2535',
        otherNum4:'0.2',
        otherNum5:'0',
      },{
        historyDate:'2022-06-06',
        testTimes:'第2次',
        otherNum1:'2539',
        otherNum2:'0',
        otherNum3:'2539',
        otherNum4:'0',
        otherNum5:'0',
      },{
        historyDate:'2022-06-05',
        testTimes:'第1次',
        otherNum1:'2539',
        otherNum2:'0',
        otherNum3:'2539',
        otherNum4:'0',
        otherNum5:'0',
      },],
      otherData4_2:[{
        historyDate:'2022-06-03',
        testTimes:'第1次',
        otherNum1:'2539',
        otherNum2:'0',
        otherNum3:'2539',
        otherNum4:'0',
        otherNum5:'0',
      },],


    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    change1(){
      if(this.value===false){
        this.txt1=this.txt1_1
        this.num4=this.num4_1
        this.num5=this.num5_1
        this.num6=this.num6_1
        this.kind=this.kind1


        this.buildData0=this.buildData0_1
        this.buildData1=this.buildData1_1
        this.buildData2=this.buildData2_1
        this.otherData1=this.otherData1_1
        this.otherData4=this.otherData4_1
      }
      else{
        this.txt1=this.txt1_2
        this.num4=this.num4_2
        this.num5=this.num5_2
        this.num6=this.num6_2
        this.kind=this.kind2

        this.buildData0=this.buildData0_2
        this.buildData1=this.buildData1_2
        this.buildData2=this.buildData2_2
        this.otherData1=this.otherData1_2
        this.otherData4=this.otherData4_2
      }
    },
  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.pageTop{
  font-size:25px;
  text-align: center;
}
.dateSelect{
  width:500px;
}

.inLine{
  font-size: 15px;
  display: inline-block;
  word-spacing: 15px;
}
.resultTitle{
  font-size: 35px;
  margin-top: 25px;
}
.resultAll{
  font-size: 25px;
  margin-top: 25px;
}

.resultTable{
  margin-top: 25px;
}

</style>
